import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from 'contexts/AppContext'
import { useContentfulContext } from 'hooks/useContentful'
import { Stack, Button, Tabs, Box, Typography, Divider } from '@newulife/common'
import { Tab } from '@mui/material'
import { findIndex } from 'lodash'

const NAV = [
  {
    id: 'informationNav',
    param: 'info',
    field: 'item1',
  },
  {
    id: 'websiteNav',
    param: 'website',
    field: 'item2',
  },
  {
    id: 'addressNav',
    param: 'address',
    field: 'item3',
  },
  {
    id: 'accountNav',
    param: 'account',
    field: 'item4',
  },
  {
    id: 'membershipNav',
    param: 'membership',
    field: 'item5',
  },
  {
    id: 'notificationsNav',
    param: 'notifications',
    field: 'item6',
  },
  {
    id: 'documentsNav',
    param: 'documents',
    field: 'item7',
    whitelist: ['TAIWAN', 'MALAYSIA', 'THAILAND', 'SINGAPORE'],
  },
]

export default function ContentNavBar() {
  const navigate = useNavigate()
  const data = useContentfulContext({
    entryPoint: 'fields.headerRef',
  })
  const { pageID } = useParams()
  const { buttonUrl, buttonText, title } = data?.fields
  const {
    state: {
      rep: { country },
    },
  } = useApp()

  return (
    <Stack
      gap={2}
    >
      <Typography
        variant="body2"
        color="neutralCool.main"
        sx={theme => ({
          display: {
            xs: 'none',
            lg: 'initial',
          },
          pb: 1,
          borderBottom: `1px solid ${theme.palette.neutralCool.main}`,
        })}
      >
        {title}
      </Typography>
      <Tabs
        orientation="vertical"
        indicatorColor="secondary"
        textColor="secondary"
        value={findIndex(NAV, { param: pageID })}
      >
        {NAV.map(o => (o.whitelist ? o.whitelist.includes(country) : true) && (
          <Tab
            key={o.id}
            id={o.id}
            data-testid={`nav_${data?.fields?.[o.field]?.replace(/\W/g, '')}`}
            label={data?.fields?.[o.field]}
            sx={{
              textAlign: 'left',
              backgroundColor: 'transparent !important',
            }}
            onClick={() => navigate(`/profile/${o.param}`)}
          />
        ))}
      </Tabs>
      <Box
        px={1}
        display="grid"
        justifyContent="flex-start"
      >
        <a
          href={buttonUrl}
        >
          <Button
            variant="outlined"
          >
            {buttonText}
          </Button>
        </a>
      </Box>
    </Stack>
  )
}
