import { isArray } from 'lodash'
import { get, post } from './request'

export const accountApi = {
  getEntityTypes: async () => {
    try {
      const response = await get('/api/getEntityTypes')

      return isArray(response) ? response : []
    } catch {
      console.error('Problem retrieving account types.')
    }
  },
  changePassword: async ({ form }) => {
    try {
      const response = await post('/api/profileChangePassword', { ...form })
      return response
    } catch {
      console.error('Problem changing password.')
    }
  },
  getProfileInfoForGetMembership: async ({ repDID }) => {
    try {
      const response = await post('/api/getProfileInfoForGetMembership', {
        repDID,
      })
      return typeof response === 'object' ? response : {}
    } catch {
      console.error(
        `Problem getting membership information for repDID: ${repDID}.`
      )
    }
  },
  getRankInfo: async ({ repDID }) => {
    try {
      const response = await post('/api/profileGetRankInfo', {
        repDID,
      })
      return typeof response === 'object' ? response : {}
    } catch {
      console.error(`Problem getting rank information for repDID: ${repDID}.`)
    }
  },
}
