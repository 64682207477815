import { Dialog, Stack, Box } from '@newulife/common'

export default function Modal({
  id = '',
  onClose,
  children,
}) {

  return (
    <Dialog
      id={id}
      open
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 600,
        }
      }}
    >
      {!!onClose && (
        <Box 
          tabindex="0"
          role="button"
          onClick={onClose} 
          sx={{
            padding: '20px',
            border: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
            cursor: 'pointer',
            transition: 'stroke 0.2s ease',
            backgroundColor: 'transparent',
            '&:focus, &:hover': {
                backgroundColor: 'transparent'
            }
        }}
        aria-label="close"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke="#747679" d="M7 7L1 12.9977L7 7ZM7 7L1 1L7 7ZM7 7L12.9977 1L7 7ZM7 7L13 13L7 7Z" strokeWidth="2" />
          </svg>
        </Box>
      )}
      <Stack
        p={{
          xs: 2,
          sm: 3,
        }}
      >
        {children}
      </Stack>
    </Dialog>
  )
}
