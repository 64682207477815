import { atom } from 'recoil';

export const devToolsState = atom({
  key: 'devToolsState',
  default: {
    overridePhoneValidationApiCall: false,
    phoneValidationResult: 'success',
    disableVerificationRedirect: false,
    verificationSubmissionResult: 'api',
    // ... add more boolean values as needed
  },
})