// Responsible for initializing selected navigation tab on initial load of the app.
export const getInitialStateByRef = ({ ref, country }) => {
  switch (ref) {
    case 'enrollment':
      return 'website';
    case 'twenrollment':
      return country === 'TAIWAN' ? 'documents' : 'info';
    default:
      return 'info';
  }
};