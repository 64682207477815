import React from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil';
import { AppContextProvider } from 'contexts/AppContext'
import { ErrorContextProvider } from 'contexts/ErrorContext'
import { ModalContextProvider } from 'contexts/ModalContext'
import 'styles/app.min.css'
import 'styles/profile.min.css'
import Routes from './routes'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@newulife/common'
import { EnvironmentProvider, useEnvironment } from 'contexts/EnvironmentContext'
import DevTools from 'components/tools/DevTools'


const RootComponent = () => {
  const isDevEnvironment = useEnvironment();

  return (
    <RecoilRoot>
    <ErrorContextProvider>
      <AppContextProvider>
        <ThemeProvider>
          <EnvironmentProvider>
            <ModalContextProvider>
              <Routes />
              {isDevEnvironment && <DevTools />}
            </ModalContextProvider>
          </EnvironmentProvider>
        </ThemeProvider>
      </AppContextProvider>
    </ErrorContextProvider>
  </RecoilRoot>
  )
}
const mountRoot = createRoot(document.getElementById('root'))

mountRoot.render(<RootComponent />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
