import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { devToolsState } from "../../state";
import {
  Drawer,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Divider,
  Select,
  MenuItem,
} from "@newulife/common";

export default function DevTools() {
  const [devToolsSettings, setDevToolsSettings] = useRecoilState(devToolsState);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (drawerOpen !== true) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  const handleChange = (event) => {
    setDevToolsSettings({
      ...devToolsSettings,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSelectChange = (event) => {
    setDevToolsSettings({
      ...devToolsSettings,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <Button
        onClick={toggleDrawer()}
        style={{
          position: "fixed",
          top: "20px",
          left: "50%",
          zIndex: "9999",
          color: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
          transform: "translateX(-50%)",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="16"
          width="20"
          viewBox="0 0 640 512"
        >
          <path
            opacity="1"
            fill="#fff"
            d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"
          />
        </svg>
      </Button>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Stack style={{ width: 260, padding: 10 }}>
          <h2>Dev Tools</h2>
          <FormControlLabel
            control={
              <Checkbox
                name="overridePhoneValidationApiCall"
                checked={devToolsSettings.overridePhoneValidationApiCall}
                onChange={handleChange}
              />
            }
            label="Override Phone Validation Api Call"
            style={{ padding: "10px 0 15px" }}
          />
          {devToolsSettings.overridePhoneValidationApiCall && (
            <Select
              value={devToolsSettings.phoneValidationResult || "success"}
              onChange={handleSelectChange}
              style={{ width: "100%", margin: "10px 0px 20px" }}
              name="phoneValidationResult"
            >
              <MenuItem value="success">Success</MenuItem>
              <MenuItem value="error">Error</MenuItem>
            </Select>
          )}
          <Divider />
          <FormControlLabel
            control={
              <Checkbox
                name="disableVerificationRedirect"
                checked={devToolsSettings.disableVerificationRedirect}
                onChange={handleChange}
              />
            }
            label="Disable Verification Submission Redirect"
            style={{ padding: "10px 0 15px" }}
          />
          {devToolsSettings.disableVerificationRedirect && (
          <Select
            value={devToolsSettings.verificationSubmissionResult || "api"}
            onChange={handleSelectChange}
            style={{ width: "100%", margin: "10px 0px 20px" }}
            name="verificationSubmissionResult"
          >
            <MenuItem value="api">Hit Api</MenuItem>
            <MenuItem value="error">Fake Error</MenuItem>
          </Select>
          )}
          <Divider />
          {/* Add more checkboxes as needed */}
        </Stack>

        {/* Additional options can go here */}
      </Drawer>
    </div>
  );
}
