import { useEffect, useState, useMemo, Fragment } from 'react'
import TermsModal from './TermsModal'
import { 
  Stack, 
  Typography, 
  Divider, 
  Checkbox, 
  FormControlLabel,
  Link,
} from '@newulife/common'
import { useContentfulContext } from 'hooks/useContentful'
import { isEmpty, find } from 'lodash'

const TermsAndConditions = ({
  form,
  onSetForm,
  onAddToForm,
  fields,
  terms,
}) => {
  const { title } = useContentfulContext({ entryPoint: 'fields.inputModelArray.2.fields' })
  const contentfulKRTerms = useContentfulContext({ entryPoint: 'fields.inputModelArray.2.fields.listArray.0.fields' })
  const isKoreaTerms = true
  const [modalOpen, setModalOpen] = useState({ open: false, modalProps: {} })
  const allChecked = form.terms && Object.values(form.terms).every(term => term?.IsAgreedTo) && !isEmpty(form.terms)
  const [
    requiredOptions,
    optionalOptions,
    totalTerms,
  ] = useMemo(() => {
    if (!isKoreaTerms) return [[], []]

    const mapTerms = checkboxItems => {
      return checkboxItems?.map?.(o => {
        let temp = {}
        terms?.forEach(o1 => {
          if (o1.AreaId === o?.fields?.byDesignAreaId) temp = o1
        })
        if (isEmpty(temp)) return null
        return {
          ...temp,
          ...o.fields,
        }
      })?.filter(o => !!o) || []
    }

    const tempRequired = mapTerms(contentfulKRTerms.requiredCheckboxItems)
    const tempOptional = mapTerms(contentfulKRTerms.optionalCheckboxItems)

    return [
      tempRequired,
      tempOptional,
      (tempRequired?.length || 0) + (tempOptional?.length || 0),
    ]
  }, [
    terms,
    isKoreaTerms,
    contentfulKRTerms,
  ])

  const handleCheckAll = e => {
    if (allChecked) {
      document.querySelectorAll('.js-check').forEach(el => {
        if (el.querySelector('input').checked) el.querySelector('input').click()
      })
    } else {
      document.querySelectorAll('.js-check').forEach(el => {
        if (!el.querySelector('input').checked) el.querySelector('input').click()
      })
    }
  }

  const handleTermsChange = (idx, idx1, field) => {
    onSetForm(prev => {
      const terms = prev.terms?.map?.(o => {
        return o.TermId === field?.TermId ? { 
          ...o, 
          IsAgreedTo: !!!o.IsAgreedTo,
          ...(o.MiscField ? { MiscField: o.MiscField } : {})
         } : o
      }) || []

      return {
        ...prev,
        terms,
      }
    })
  }

  useEffect(() => {
    if (requiredOptions && optionalOptions) {
      onAddToForm({ 
        terms: [
          ...requiredOptions?.map(o => ({ 
            TermId: o?.TermId, 
            IsAgreedTo: o?.IsAgreedTo,
          })), 
          ...optionalOptions?.map(o => ({ 
            TermId: o?.TermId, 
            IsAgreedTo: o?.IsAgreedTo, 
            MiscField: o?.MiscField,
          })), 
        ],
      })
    }
  }, [requiredOptions, optionalOptions])

  if (totalTerms === 0) return null

  return (
    <Stack
      gap={3}
      className="form-max"
    >
      <Typography
        variant="header2"
        fontWeight="400"
        textAlign="center"
      >
        {title}
      </Typography>
      <Stack
        gap={4}
      >
        {totalTerms > 1 && (
          <>
            <div
              className="form-max flex-20 column"
            >
              {/* {sectionList?.map((item) => <p key={item}>{item}</p>)} */}
              <Stack
                gap={2}
              >
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                  }}
                  control={
                    <Checkbox
                      id="select-all"
                      onChange={handleCheckAll}
                      checked={allChecked}
                    />
                  }
                  label={
                    <Stack gap={1} pt={0.3}>
                      <Typography
                        as="label"
                        variant="body3"
                        htmlFor="select-all"
                      >
                        {contentfulKRTerms.selectAllCheckboxItem?.fields?.name}
                      </Typography>
                    </Stack>
                  }
                />
                <Typography
                  variant="body3"
                >
                  {contentfulKRTerms.selectAllCheckboxItem?.fields?.disclaimerText}
                </Typography>
              </Stack>
            </div>
            <Divider />
          </>
        )}
        <div
          className="form-max flex-20 column"
        >
          {[requiredOptions, optionalOptions].map((o, idx) => (
            (o?.length > 0) && (
              <Fragment
                key={`termsSection${idx}`}
              >
                {optionalOptions?.length > 0 && (
                  <Typography
                    variant="body1"
                  >
                    {idx === 0 ? contentfulKRTerms.requiredSectionText : contentfulKRTerms.optionalSectionText}
                  </Typography>
                )}

                {o?.map?.((field, idx1) => (
                  <FormControlLabel
                    key={`terms${idx}${idx1}`}
                    sx={{
                      alignItems: 'flex-start',
                    }}
                    control={
                      <Checkbox
                        className="js-check"
                        id={field?.TermId.toString()}
                        name={field?.TermId.toString()}
                        checked={!!find(form.terms, { TermId: field?.TermId })?.IsAgreedTo}
                        onChange={() => handleTermsChange(idx, idx1, field)}
                      />
                    }
                    label={
                      <Stack gap={1} pt={0.3}>
                        {field?.checkboxLabel && (
                          <Typography
                            as="label"
                            variant="body3"
                            htmlFor={field?.TermId}
                          >
                            {field?.checkboxLabel}
                          </Typography>
                        )}
                        {field?.linkedFile?.fields?.file?.url ? (
                          <Link
                            href={field?.linkedFile?.fields?.file?.url}
                            target="_blank"
                            underline="hover"
                            sx={{
                              fontWeight: 500,
                              display: 'block',
                            }}
                          >
                            {field?.linkLabel}
                          </Link>
                        ) : field?.modalText ? (
                          <Link
                            underline="hover"
                            sx={{
                              fontWeight: 500,
                              display: 'block',
                            }}
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              setModalOpen({ open: true, modalProps: field })
                            }}
                          >
                            {field?.linkLabel}
                          </Link>
                        ) : null}
                      </Stack>
                    }
                  />
                ))}
              </Fragment>
            )
          ))}
        </div>
      </Stack>
      {modalOpen.open && (
        <TermsModal
          {...modalOpen.modalProps}
          onClose={() => setModalOpen({ open: false, modalProps: {} })}
        />
      )}
    </Stack>
  )
}

export default TermsAndConditions