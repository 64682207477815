import { useState } from 'react'
import { 
  Stack, 
  Typography, 
  Button, 
  Alert, 
  Grid, 
} from '@newulife/common'
import { useCheckPlus } from 'hooks/useCheckPlus'
import { useContentfulContext } from 'hooks/useContentful'
import { ReactComponent as PhoneIcon } from 'assets/images/mobile-screen-button-solid.svg'

const PhoneVerification = ({
  form,
  onAddToForm,
}) => {
  const [phoneStatus, setPhoneStatus] = useState(undefined)
  const [phoneErrorCode, setPhoneErrorCode] = useState(undefined)
  const {
    title,
    listArray,
  } = useContentfulContext({ entryPoint: 'fields.inputModelArray.0.fields' })

  const handleSuccess = data => {
    onAddToForm({ phone: data.phone }, { IsPhoneValidated: data.PHONE_STATUS === 'success' })
    setPhoneStatus(data.PHONE_STATUS)
    setPhoneErrorCode(data.PHONE_ERROR_CODE)
  }

  const { 
    startVerification, 
  } = useCheckPlus({
    onSuccess: handleSuccess,
  })

  const handleClear = () => {
    onAddToForm({ phone: null }, { IsPhoneValidated: false })
    setPhoneStatus(undefined)
    setPhoneErrorCode(undefined)
  }

  return (
    <Stack
      display="grid"
      gap={3}
      className="form-max"
      alignItems="center"
    >
      <Typography
        variant="header2"
        fontWeight="400"
        align='center'
      >
        {title}
      </Typography>
      <Stack
        display="grid"
        gap={1}
        alignItems="center"
      >
        {form.phone ? (
          <Stack
            display="grid"
            gridAutoFlow="column"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body2"
              color="neutralCool.main"
            >
              {listArray?.[0]?.fields?.name}: {form.phone}
            </Typography>
            <Typography
              sx={{
                cursor: 'pointer',
              }}
              color="primary.main"
              onClick={handleClear}
            >
              {listArray?.[2]?.fields?.name}
            </Typography>
          </Stack>
        ) : (
          <>
            {/* <Typography
              variant="body2"
              color="neutralCool.main"
              align='center'
            >
              Phone Number Verification
            </Typography> */}
            <Button onClick={startVerification}>
              <PhoneIcon />
              {listArray?.[1]?.fields?.name}
            </Button>
          </>
        )}
      </Stack>
      {phoneStatus && (
        <Alert
          severity={phoneStatus}
          variant="standard"
        >
          <Grid container gap={0.5} direction="column">
            <Typography align="center" variant="body2">
              {phoneStatus === 'success' ? listArray?.[4]?.fields?.name : `${listArray?.[3]?.fields?.name}: ${phoneErrorCode}`}
            </Typography>
          </Grid>
        </Alert>
      )}
    </Stack>
  )
}

export default PhoneVerification