import { useEffect, useState } from 'react'
import { isArray } from 'lodash'
import { languageApi } from 'api/language'
import { useApp } from 'contexts/AppContext'

export const useLanguages = () => {
  // Context
  const {
    state: { rep },
    dispatch,
  } = useApp()

  // State
  const [hasDispatched, setHasDispatched] = useState(false)

  useEffect(() => {
    const getLanguages = async () => {
      const { country } = rep
      const res = await languageApi.getAllByCountry({ country })

      if (isArray(res)) {
        dispatch({ type: 'updateLanguages', languages: [...res] })
        setHasDispatched(true)
      }
    }

    if (rep?.country) getLanguages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rep?.country])

  return hasDispatched
}
