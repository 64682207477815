import { useMemo, memo, Suspense, lazy } from 'react'
import { useParams } from 'react-router-dom'
import AppLayout from 'components/layouts/AppLayout'
import { useContentfulContext } from 'hooks/useContentful'
import { useLanguages } from 'hooks/useLanguages'
import { useAccountTypes } from 'hooks/useAccountTypes'
import { CircularProgress } from '@newulife/common'

const PAGES = {
  'info': { page: lazy(() => import('./PersonalInformationPage')), ref: 'banner1Ref' },
  'website': { page: lazy(() => import('./PersonalWebsitePage')), ref: 'banner2Ref' },
  'address': { page: lazy(() => import('./AddressShippingPage')), ref: 'banner3Ref' },
  'account': { page: lazy(() => import('./UWalletWithdrawalPage')), ref: 'banner4Ref' },
  'membership': { page: lazy(() => import('./MembershipPlacementPage')), ref: 'banner5Ref' },
  'notifications': { page: lazy(() => import('./NotificationsPage')), ref: 'banner6Ref' },
  'documents': { page: lazy(() => import('./DocumentsPage')), ref: 'banner7Ref' },
}

export default memo(() => {
  const { pageID } = useParams()
  const data = useContentfulContext({})
  const languages = useLanguages()
  const accountTypes = useAccountTypes()

  const [Page, ref] = useMemo(() => [
    PAGES[pageID]?.page,
    data?.fields?.[PAGES[pageID]?.ref] || {},
  ], [pageID, data?.fields])

  if (!data || !languages || !accountTypes || !Page) {
    return null
  }

  return (
    <AppLayout>
      <Suspense
        fallback={
          <CircularProgress
            size="lg"
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              m: 'auto',
            }}
          />
        }
      >
        <Page data={ref} />
      </Suspense>
    </AppLayout>
  )
})