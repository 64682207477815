import { createContext, useContext, useReducer } from 'react'
import ConfirmAddressModal from 'components/modals/ConfirmAddressModal'
import InvalidAddressModal from 'components/modals/InvalidAddressModal'
import { initialState } from 'constants/modal'

const ModalContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        props: { ...action.props },
        open: true,
      }
    case 'close':
      return initialState
    default:
      throw new Error(`Unhandled action type in AppContext: ${action.type}`)
  }
}

const ModalRenderer = ({ props }) => {
  const { modalType } = props
  switch (modalType) {
    case 'confirm-address':
      return <ConfirmAddressModal {...props} />
    case 'invalid-address':
      return <InvalidAddressModal {...props} />
    default:
      throw new Error(`Unhandled modal type in ModalRenderer: ${modalType}`)
  }
}

// Used to manage modal types and the opened/closed status of them.
const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {state.open && <ModalRenderer props={state.props} />}
      {children}
    </ModalContext.Provider>
  )
}

// Custom hook to consume context.
const useModal = () => {
  const context = useContext(ModalContext)

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContextProvider')
  }

  return context
}

export { ModalContextProvider, useModal }
