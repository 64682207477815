import { useState } from 'react'
import { useModal } from 'contexts/ModalContext'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useContentful } from 'hooks/useContentful'
import CheckboxInput from 'components/inputs/CheckboxInput'
import {
  AddressText,
  SOAddressText,
} from 'components/texts/AddressText'
import Modal from './Modal'
import { Typography, Stack, Button } from '@newulife/common'

export default function ConfirmAddressModal({
  addressType,
  form,
  so,
  callback,
}) {
  const { dispatch } = useModal()
  const data = useContentful('2bOrqF0NGNLS4nG7xGpeua') // Confirm Your Address Modal Info
  const [overwrite, setOverwrite] = useState(true)

  if (!data) return null

  const onClick = () => {
    callback({ overwrite })
    dispatch({ type: 'close' })
  }

  const {
    title,
    item1,
    item2,
    buttonText,
    richText: shippingText,
    richText1: billingText,
  } = data.fields

  return (
    <Modal
      id="confirm-address-modal"
    >
      <Stack
        gap={4}
      >
        <Stack
          gap={2}
          textAlign="center"
        >
          <Typography
            variant="header1"
            color="neutralCool.dark"
          >
            {title}
          </Typography>
          <Typography
            variant="body3"
            maxWidth="440px"
            sx={{
              mx: 'auto',
              '& > *': {
                mb: 0,
              },
            }}
          >
            {addressType === 'Shipping' && documentToReactComponents(shippingText)}
            {addressType === 'Billing' && documentToReactComponents(billingText)}
          </Typography>
        </Stack>
        <Stack
          display="grid"
          justifyItems="center"
          gap={3}
        >
          <Stack
            gap={2}
          >
            <Typography
              variant="header2"
              color="neutralCool.main"
              textAlign="center"
            >
              {item1}
            </Typography>
            <CheckboxInput
              inputId="CurrentAddress"
              inputName="CurrentAddress"
              inputAriaLabel="CurrentAddress"
              inputChecked={!overwrite}
              inputOnChange={() => setOverwrite(false)}
            >
              <AddressText addressType={addressType} form={form} />
            </CheckboxInput>
          </Stack>
          <Stack
            gap={2}
          >
            <Typography
              variant="header2"
              color="neutralCool.main"
              textAlign="center"
            >
              {item2}
            </Typography>
            <CheckboxInput
              inputId="SoAddress"
              inputName="SoAddress"
              inputAriaLabel="SoAddress"
              inputChecked={overwrite}
              inputOnChange={() => setOverwrite(true)}
            >
              <SOAddressText so={so} />
            </CheckboxInput>
          </Stack>
        </Stack>
        <Button
          data-testid={`${buttonText?.replace(/\W/g, '').toLowerCase()}_btn`}
          sx={{
            mx: 'auto',
          }}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Stack>
    </Modal>
  )
}
