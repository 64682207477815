import { useEffect, useCallback } from 'react'
import { post } from 'api/request'
import { useRecoilState } from 'recoil'
import { devToolsState } from 'state'
import { useEnvironment } from '../contexts/EnvironmentContext';


// Test data for local and dev environments to sidestep NICE Phone Validation
const TEST = (code = 'success') =>
  `code=${code}&EncodeData=AgAFQ0I0MTmKy9eTuwY0EVrn1726ltGEEoRT14m3zL1x3INlTc0x5LADbWrS5RN%2BV%2B/BnzfEToRxLQHP6rh2DZFNLHLW3eSB9wfWEx/yOKAVSnH4uaExq2awCUBpA1ZtC4Vu3ho0GSsxZZmRGFLMQvtaEFOKEbASD3EZCmXFsPafHvGiGQNC3cxQLGtMsfAatFzwnBAknn46cAk0iulHLBfclc%2BStkQguoxymEIr9dSqK7SrwS9ww29Nf3wgA2UkW/YS7A7UxP5OcSeS6x9vJydp02sMtOaROQI/ASbDrBIvZQ5Ab%2BR3dn/GU0b5TQYPZjfsdZpaIbGUUwrOV/7SlUkJ/mrRtrN4ljS4tmY76DcxFBMXUMCuSnvT9mQKd7R8tot4O9x0AM6a3nVwH%2BSKoE2Q%2By2NWS2dFprsESRRVj9AnZ4izASTjuWYPjer0T9uH1qZuCIv3snW/Mfx7G/lFRKM7s9ikwqYIpsZvLbf1fTjM3Cx5ve4n/zrKkF9JgU9S8FNjpe28EdFKlFW53UdnjwAaUwyfZqsqsDR65Cm4DYVQ8NDRsPkOilv8AWK4I4r/bbH5p4HJO%2BlzXTAEHI1DhV9Q82Q/3wquEo8cyPv7CqXfJb/TfsI/A==`
  
// export type PhoneInput = {
//   PHONE: any;
//   PHONE_STATUS: string | null;
//   PHONE_ERROR_CODE: any;
//   miscFields: {
//     IsPhoneValidated: boolean;
//   };
// }

export function useCheckPlus({
  onSuccess,
}) {

  const [devToolsSettings, setDevToolsSettings] = useRecoilState(devToolsState)
  const isDevEnvironment = useEnvironment()

  const startVerification = useCallback(async () => {
    try {
      const response = await post('/api/enrollment/ko/checkplus/cipher', {
        authType: "M",
        customize: "",
        successUrl: `${window.location.origin}/nice-response?code=success`,
        errorUrl: `${window.location.origin}/nice-response?code=error`
      })
      // This is only for manual testing locally and in dev - keeping it for convenience
      if (isDevEnvironment && devToolsSettings.overridePhoneValidationApiCall) {
        // 'success' or 'error' to see the two paths
        window.open(`${window.location.origin}/nice-response?${TEST(devToolsSettings.phoneValidationResult)}`)
      }
      // } else {
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
      form.target = 'popupChk'

      const inputM = document.createElement('input')
      inputM.type = 'hidden'
      inputM.name = 'm'
      inputM.value = 'checkplusService'

      const inputEncData = document.createElement('input')
      inputEncData.type = 'hidden'
      inputEncData.name = 'EncodeData'
      inputEncData.value = response.token

      form.appendChild(inputM)
      form.appendChild(inputEncData)

      document.body.appendChild(form)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no')
      form.submit()

      document.body.removeChild(form)
    } catch(error) {
        console.log(error)
    }
  }, [devToolsSettings, isDevEnvironment])

  useEffect(() => {
    const messageListener = async e => {
      if (e.origin !== window.location.origin) return

      if (e.data.status) {
        try {
          const response = await post('/api/enrollment/ko/checkplus/decipher', {
            encodeData: decodeURIComponent(e.data.encodeData),
            plainData: e.data.plainData,
          })
          onSuccess({
            PHONE_STATUS: e.data.status === 'success' ? e.data.status : null,
            PHONE_ERROR_CODE: response.errorCode,
            phone: e.data.status === 'success' ? response.phoneNumber : null,
            miscFields: {
              IsPhoneValidated: e.data.status === 'success'
            },
          })
        } catch(error) {
          console.log(error)
        }
      }
    }

    window.addEventListener('message', messageListener)
    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [])

  return {
    startVerification, 
  }
}
