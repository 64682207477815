import { useEffect, useState } from 'react'
import { isArray } from 'lodash'
import { accountApi } from 'api/account'
import { useApp } from 'contexts/AppContext'

export const useAccountTypes = () => {
  // Context
  const { dispatch } = useApp()

  // State
  const [hasDispatched, setHasDispatched] = useState(false)

  useEffect(() => {
    const getAccountTypes = async () => {
      const res = await accountApi.getEntityTypes()

      if (isArray(res)) {
        dispatch({ type: 'updateAccountTypes', accountTypes: [...res] })
        setHasDispatched(true)
      }
    }

    getAccountTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return hasDispatched
}
