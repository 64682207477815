import { isEmpty } from 'lodash'

export default function Loader({ text = '', active = false }) {

  if (!active) return null

  return (
    <div className="modal--wrapper loader-state fade-in">
      <LoaderRing text={text} active={active} />
    </div>
  )
}

export function LoaderRing({ text = '', active = false }) {
  
  if (!active) return null

  return (
    <div className="loader-ring fadeIn">
      <div></div>
      {!isEmpty(text) && <h2>{text}</h2>}
    </div>
  )
}
