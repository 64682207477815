import config from 'config'

// QA Test Rep Data
// const qaTestRep = {
//   firstName: 'Matt',
//   lastName: 'hutka',
//   email: 'aWip@bydesign.com',
//   address1: '2623 Pleasant Hill Road 2',
//   address2: null,
//   city: 'PLEASANT HILL',
//   state: 'CA',
//   postalCode: '94523',
//   country: 'USA',
//   shipAddress1: '2623 Pleasant Hill Rd',
//   shipAddress2: null,
//   shipCity: 'PLEASANT HILL',
//   shipState: 'CA',
//   shipCountry: 'USA',
//   shipPostalCode: '94523',
//   RomanizedFirstName: '',
//   RomanizedLastName: '',
//   RomanizedCompany: '',
//   personalURL: 'rQExlaeSEh',
//   DateOfBirth: '',
//   RepDID: '826006',
//   HasTaxId: false,
//   phone1: '17589636631',
//   shipPhone: '17589636631',
//   DefaultLocaleID: 1,
//   DefaultLocaleName: 'English',
//   PublicImageUrl: null,
//   RepEntityTypeID: 1002,
//   RepEntityType: 'Individual',
// }

// KOREA
const qaTestRep = {
  firstName: '진수',
  lastName: '김',
  email: 'jinsu@gmail.com',
  address1: '서울시 마포구 상암동',
  address2: null,
  city: '서울',
  state: '서울특별시',
  postalCode: '12345',
  country: 'KOREA (THE REPUBLIC OF)',
  shipAddress1: '서울시 마포구 상암동',
  shipAddress2: null,
  shipCity: '서울',
  shipState: '서울특별시',
  shipCountry: 'KOREA (THE REPUBLIC OF)',
  shipPostalCode: '12345',
  RomanizedFirstName: '',
  RomanizedLastName: '',
  RomanizedCompany: '',
  personalURL: 'jinsu',
  DateOfBirth: '',
  RepDID: '826006',
  HasTaxId: false,
  phone1: '010-1234-5678',
  shipPhone: '010-1234-5678',
  DefaultLocaleID: 1,
  DefaultLocaleName: '한국어',
  PublicImageUrl: null,
  RepEntityTypeID: 1002,
  RepEntityType: '개인',
}

 // 서울 관악구 봉천동 3가 17-20

// Dev Test Rep
const devTestRep = {
  firstName: 'Usfname',
  lastName: 'Uslname',
  email: 'fnameus403921@testemail.com',
  address1: '350 S State St',
  address2: '',
  city: 'Salt Lake City',
  state: 'UT',
  postalCode: '84111-2303',
  county: 'Salt Lake',
  country: 'USA',
  shipAddress1: '350 S State St',
  shipAddress2: '',
  shipCity: 'Salt Lake City',
  shipState: 'UT',
  shipCounty: 'Salt Lake',
  shipCountry: 'USA',
  shipPostalCode: '84111-2303',
  RomanizedFirstName: null,
  RomanizedLastName: null,
  RomanizedCompany: null,
  personalURL: '',
  DateOfBirth: null,
  RepDID: '808665',
  HasTaxId: false,
  phone1: '3333334444',
  shipPhone: '3333334444',
  DefaultLocaleID: 1,
  DefaultLocaleName: 'English',
  PublicImageUrl: null,
  RepEntityTypeID: 0,
  RepEntityType: 'Not Specified',
}

export const defaultRep =
  // config.NODE_ENV === 'development' ? devTestRep : {}
  config.NODE_ENV === 'development' ? qaTestRep : {}
