import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const RichText = ({
  className,
  text,
  style,
  h3ClassName = '',
  tableClassName = '',
  tableRowClassName = '',
  tableHeaderClassName = '',
  tableCellClassName = '',
  tableHeaderCellClassName = '',
}) => {
  // Options defined in @contentful/rich-text-types.
  const Bold = ({ children }) => <strong>{children}</strong>;

  const Text = ({ children }) => {
    // const contentWithLineBreaks = children.split('\n').map((line, index, array) => (
    //   <React.Fragment key={index}>
    //     {line}
    //     {index < array.length - 1 && <br />}
    //   </React.Fragment>
    // ));
    return <p className={className} style={style}>{children}</p>
  };

  // Split the text on newlines and create a fragment with line breaks
const renderTextNode = (text) => {
  return text.split('\n').map((line, index, array) => (
    <React.Fragment key={index}>
      {line}
      {index < array.length - 1 ? <br /> : null}
    </React.Fragment>
  ));
};

  const H3 = ({ children }) => <h3 className={h3ClassName}>{children}</h3>;

  const Table = ({ children }) => (
    <div className={`scroll-x ${tableClassName}`}><table>{children}</table></div>
    );
  const TableRow = ({ isHeader, children }) => {
    const rowClasses = isHeader ? tableHeaderClassName + ' header-row' : tableRowClassName;

    return (
      <tr className={rowClasses}>{children}</tr>
    )
  };
  const TableCell = ({ children }) => (
    <td className={tableCellClassName}>{children}</td>
  );
  const TableHeaderCell = ({ children }) => (
    <td className={tableHeaderCellClassName}>{children}</td>
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return(<Text>{children}</Text>)
      },
      [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <Table>
            {children}
          </Table>
        );
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        const isHeader = (node.content[0].nodeType == 'table-header-cell') ? true : false

        return (
          <TableRow isHeader={isHeader}>
            {children}
          </TableRow>
        );
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return <TableHeaderCell>{children}</TableHeaderCell>;
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return <TableCell>{children}</TableCell>;
      },
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return documentToReactComponents(text, options);
};