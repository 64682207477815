import { Radio, Stack } from '@newulife/common';


export default function CheckboxInput({
  inputId = '',
  inputName = '',
  inputAriaLabel = '',
  inputChecked = false,
  inputOnChange = () => { },
  children,
}) {

  return (
    <Stack
      display="grid"
      gridAutoFlow="column"
      gap={1}
      alignItems="flex-start"
      sx={{
        cursor: 'pointer',
      }}
      onClick={inputOnChange}
    >
      <Radio
        data-testid={inputId}
        id={inputId}
        name={inputName}
        aria-label={inputAriaLabel}
        checked={inputChecked}
      />
      {children}
    </Stack>
  )
}
