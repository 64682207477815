import config from 'config';

export default function UserAvatar({ url }) {
  // ByDesign
  const host = config.REACT_APP_BYDESIGN_IMAGE_HOST_URL;

  return (
    <img
      src={`${host}${url}`}
      alt=""
      width="50px"
      height="50px"
      className="user-image"
      style={{ borderRadius: '50%' }}
    />
  );
}
