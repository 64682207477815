import { useState, useMemo, useRef } from 'react'
import { post } from 'api/request'
import { useContainerOnBlurAndFocus } from 'hooks/useContainerOnBlurAndFocus'
import { useContentfulContext } from 'hooks/useContentful'
import { 
  Stack, 
  Typography, 
  TextField, 
  Alert, 
  Grid, 
} from '@newulife/common'
import axios from 'axios'
import config from 'config'
import { useApp } from 'contexts/AppContext'

// const match = (regex, errorMessage = 'incorrect-format') => {
//   return {
//     validation: (value) => {
//       if (value && value.match(regex)) return true
//       return false
//     },
//     errorMessage,
//   }
// }

// match(hangulOnlyRegex, 'validation-hangul-only')

const getKoreaBirthday = (governmentID = '') => {
  // Parse out the birthday from the Korea RRN
  const year = governmentID.substring(0, 2)
  const month = governmentID.substring(2, 4)
  const day = governmentID.substring(4, 6)

  // Determine the full birth year
  const currentYear = new Date().getFullYear().toString().slice(-2)
  const fullBirthYear = year > currentYear ? `19${year}` : `20${year}`

  //Validate Month
  if (isNaN(month) || parseInt(month) < 1 || parseInt(month) > 12) return false

  //Validate Day
  const daysInMonth = new Date(fullBirthYear, month, 0).getDate()
  if (isNaN(day) || parseInt(day) < 1 || parseInt(day) > daysInMonth) return false

  // Return the birthday in valid format
  return `${fullBirthYear}-${month}-${day}`
}

const tenToThirteenDigitsAndHyphenRegex = /^\d+(-\d+)?$|^\d{10,13}$/

const krDuplicateIdValidation = async (taxId, rep) => {
  return await axios.post(`${config.REACT_APP_API_URL}/api/profile/validate-tax-id`, {
    taxId,
    country: 'Korea (the Republic of)',
  }, {
    headers: {
      'Authorization': `Bearer ${rep?.token}`
    },
  })
    .then((res) => {
      return res.data.isValid
    })
    .catch(() => {
      return false
    })
}
// 'kr-validation-govId'


const RealNameVerification = ({
  form,
  onAddToForm,
}) => {
  const {
    title,
    listArray,
  } = useContentfulContext({ entryPoint: 'fields.inputModelArray.1.fields' })
  const krValues = useRef([undefined, undefined])
  const [realNameIsValid, setRealNameIsValid] = useState(false)
  const [idIsDuplicate, setIdIsDuplicate] = useState(false)
  const [realNameValidating, setRealNameValidating] = useState(false)

  const alertMessage = useMemo(() => {
    const blocked = listArray?.[2]?.fields
    const invalid = listArray?.[4]?.fields
    const valid = listArray?.[3]?.fields
    return {
      [blocked.descriptiveTitle]: blocked.name,
      [invalid.descriptiveTitle]: invalid.name,
      [valid.descriptiveTitle]: valid.name,
    }
  }, [listArray])

  const {
    state: { rep },
  } = useApp()

  const realNameValidation = async () => {
    setRealNameValidating(true)
    post(`/api/enrollment/ko/realname-validation`, {
      residentId: form.residentRegistrationNumber?.replace?.('-', ''),
      name: form.realName,
    })
    .then(data => {
      const tempIdStatus = (data?.result === 'Invalid' || data?.result === 'Blocked') ? false : true
      onAddToForm({
        krIdStatus: data?.result,
        governmentID: form.residentRegistrationNumber,
      }, {
        IsNameAndIDValidated: tempIdStatus,
      })
      setRealNameValidating(false)
      setRealNameIsValid(tempIdStatus)
    })
    .catch(() => {
      onAddToForm({
        krIdStatus: null,
        governmentID: null,
      }, {
        IsNameAndIDValidated: false,
      })
      setRealNameValidating(false)
      setRealNameIsValid(false)
    })
  }

  const rrnLengthError = !form.residentRegistrationNumber?.replace('-', '')?.match?.(tenToThirteenDigitsAndHyphenRegex)
  const rrnBirthdayError = !getKoreaBirthday(form.residentRegistrationNumber)

  const isRRNValid = 
    !rrnLengthError &&
    !rrnBirthdayError

    //This errors the field, but we need to figure out a way to handle this in common library better 
    const realNameError = !krValues.current[1]?.length > 0

  const krValidation = async () => {
    if (
      isRRNValid &&
      (krValues.current[0] !== form.residentRegistrationNumber || 
        krValues.current[1] !== form.realName)
    ) {
      setRealNameValidating(true)
      const isGovIdValid = await krDuplicateIdValidation(form.residentRegistrationNumber, rep)
      setIdIsDuplicate(!isGovIdValid)
      if (
        isGovIdValid &&
        form.realName?.length > 0
        ) {
        realNameValidation()
      } else {
        onAddToForm({ KR_ID_STATUS: null }) // TODO: remove
        setRealNameValidating(false)
      }
    } else if (
      isRRNValid &&
      realNameIsValid &&
      (krValues.current[0] !== form.residentRegistrationNumber ||
        krValues.current[1] !== form.realName)
    ) {
      realNameValidation()
    }
    krValues.current = [form.residentRegistrationNumber, form.realName]
  }

  const [setRef, isFocused] = useContainerOnBlurAndFocus(krValidation)

  // const showAlert =
  //   form.krIdStatus &&
    
  //   !realNameValidating &&
  //   !isFocused &&
  //   realNameIsValid

  const showAlert =
    ['Invalid', 'Blocked', 'Valid'].includes(form.krIdStatus) &&
    !realNameValidating &&
    !isFocused &&
    isRRNValid &&
    !realNameError &&
    !idIsDuplicate

  // const forceError =
  //   idIsDuplicate &&
  //     isIdValid &&
  //     krValues.current[0] === inputs.residentRegistrationNumber ? 'kr-validation-govId' : undefined

  const handleChange = e => {
    const { name, value } = e.target
    if(name === "residentRegistrationNumber" && krValues.current[0] !== value) {
      setIdIsDuplicate(false)
    }
    onAddToForm({ [name]: value })
  }
  
  return (
    <Stack
      gap={3}
      className="form-max"
      style={{ position: 'relative' }}
    >
      <Typography
        variant="header2"
        fontWeight="400"
        align='center'
      >
        {title}
      </Typography>
      <Stack
        gap={2}
      >
        <Stack
          display="grid"
          gap={2}
          ref={setRef}
        >
          <div>
            <div>
              <TextField
                id="realName"
                name="realName"
                value={form.realName || ''}
                onChange={handleChange}
                isErrored={(realNameError && !!form.realName?.length)}
                label={listArray?.[0]?.fields?.name}
                required
              />
              {/* {realNameError && ( --- Commenting out for now, we need to address this in common library
                <Typography 
                  variant="caption" 
                  color="error"
                >
                  Value does not match the required pattern.
                </Typography>
              )} */}
            </div>
          </div>
          <div>
            <TextField 
              id="residentRegistrationNumber"
              name="residentRegistrationNumber"
              value={form.residentRegistrationNumber || ''}
              onChange={handleChange}
              isErrored={((!isRRNValid || idIsDuplicate) && !!form.residentRegistrationNumber?.length)}
              label={listArray?.[1]?.fields?.name}
              mask="999999-9999999"
              required
              maskProps={{
                alwaysShowMask: true,
              }}
            />
            {((!isRRNValid || idIsDuplicate) && !!form.residentRegistrationNumber?.length) && (
              <Typography 
                variant="caption" 
                color="error"
              >
                {rrnLengthError ? listArray?.[5]?.fields?.name : rrnBirthdayError ? listArray?.[6]?.fields?.name : idIsDuplicate ? listArray?.[8]?.fields?.name : ''}
              </Typography>
            )}
          </div>
        </Stack>
        {realNameValidating && (
          <div className={`loader-ring fadeIn`}>
            <div></div>
          </div>
        )}
        {showAlert && (
          <Alert
            severity={form.krIdStatus !== "Valid" ? "error" : "success"}
            variant="standard"
          >
            <Grid container gap={0.5} direction="column">
              <Typography align="left" variant="body2">
                {form.krIdStatus && alertMessage[`kr-real-name-validation-error-${form.krIdStatus || 'Invalid'}`]}
              </Typography>
            </Grid>
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}

export default RealNameVerification