import { useState } from 'react'
import SiteNavBar from 'components/navbars/SiteNavBar'
import ContentNavBar from 'components/navbars/ContentNavBar'
import ErrorBoundary from 'components/boundaries/ErrorBoundary'
import { useContentfulContext } from 'hooks/useContentful'
import { useError } from 'contexts/ErrorContext'
import { Container, Stack, Box, Drawer } from '@newulife/common'
import useMediaQuery from '@mui/material/useMediaQuery'

const drawerWidth = 250
const bp = 'lg'

export default function AppLayout({
  children,
}) {
  const { dispatch } = useError()
  const isBPUp = useMediaQuery((theme) => theme.breakpoints.up(bp))
  const {
    headTagModelRef,
    errorRef,
  } = useContentfulContext({ entryPoint: 'fields' })
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const showErrorMessage = () => {
    const { item1 } = errorRef?.fields
    dispatch({ type: 'showMessage', message: item1 })
  }

  return (
    <ErrorBoundary
      callback={showErrorMessage}
    >
      <Stack
        display="grid"
        minHeight="100vh"
        maxWidth="100vw"
        sx={{
          overflowX: 'hidden',
        }}
      >
        <SiteNavBar
          drawerWidth={drawerWidth}
          data={headTagModelRef}
          bp={bp}
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        />
        <Box
          bgcolor="neutralCool.lightest"
          display="grid"
        >
          <Drawer
            open={mobileNavOpen && !isBPUp}
            onClose={() => setMobileNavOpen(!mobileNavOpen)}
            variant="temporary"
            sx={{
              display: {
                [bp]: 'none',
              },
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                '& > div': {
                  height: '100%',
                  display: 'grid',
                  alignItems: 'center',
                  alignContent: 'center',
                },
              },
            }}
          >
            <ContentNavBar />
          </Drawer>
          <Box
            component="main"
            pt={13}
            pb={6}
          >
            <Container>
              <Stack
                sx={{
                  display: 'grid',
                  gap: 4,
                  gridTemplateColumns: {
                    [bp]: `${drawerWidth}px 1fr`,
                  },
                }}
              >
                {isBPUp && (
                  <ContentNavBar />
                )}
                {children}
              </Stack>
            </Container>
          </Box>
        </Box>
      </Stack>
    </ErrorBoundary>
  )
}
