import { useRef, useState, useCallback, FocusEvent } from 'react';

type FocusEventHandler = (event: FocusEvent<HTMLElement>) => void;

export function useContainerOnBlurAndFocus(handlerBlur: FocusEventHandler): [(node: HTMLElement | null) => void, boolean] {
  const ref = useRef<HTMLElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      const currentTarget = ref.current;

      if (!currentTarget || currentTarget.contains(event.relatedTarget as Node)) {
        return;
      }

      handlerBlur(event);
      setIsFocused(false);
    },
    [handlerBlur]
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      const currentTarget = ref.current;

      if (currentTarget && currentTarget.contains(event.target as Node)) {
        setIsFocused(true);
      }
    },
    []
  );

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (ref.current) {
        ref.current.removeEventListener('focusout', handleBlur as unknown as EventListener);
        ref.current.removeEventListener('focusin', handleFocus as unknown as EventListener);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener('focusout', handleBlur as unknown as EventListener);
        ref.current.addEventListener('focusin', handleFocus as unknown as EventListener);
      }
    },
    [handleBlur, handleFocus]
  );

  return [setRef, isFocused];
}
