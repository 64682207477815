import { useEffect, useState } from 'react'
import { isEmpty, isNull } from 'lodash'
import * as api from 'api/api'
import Loader from 'components/loaders/Loader'
import { useApp } from 'contexts/AppContext'
import { useQueryParams } from 'hooks/useQueryParams'
import config from 'config'

const whitelistedCountries = [
  'USA',
  'CANADA',
  'CHINA',
  'AUSTRALIA',
  'NEW ZEALAND',
  'HONG KONG',
  'TAIWAN',
  'MALAYSIA',
  'THAILAND',
  'SINGAPORE',
  'GERMANY',
  'BELGIUM',
  'UNITED KINGDOM',
  'NETHERLANDS',
  'KOREA (THE REPUBLIC OF)',
]

export default function GuidAuth({ children }) {
  // State
  const [hasDispatched, setHasDispatched] = useState(false)
  const [hasValidated, setHasValidated] = useState(false)

  // Context
  const {
    state: { rep },
    dispatch,
  } = useApp()
  const { params } = useQueryParams()

  const id = params.get('guid') || window?.sessionStorage?.getItem('guid')

  useEffect(() => {
    if (!isNull(id)) {
      window?.sessionStorage?.setItem('guid', id)
      const validateGuid = async () => {
        const res = await api.authenticate({ guid: id })

        if (res.token) {
          const repInfo = await api.getProfileInfo()

          dispatch({
            type: 'updateRep',
            rep: repInfo,
          })

          setHasDispatched(true)
        }

        setHasValidated(true)
      }

      validateGuid()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // console.log('config.NODE_ENV', id, config.NODE_ENV)

  // Development Mode.
  if (isNull(id) && (config.APP_ENV === 'development' || config.APP_ENV === 'localhost')) {
    return children
  }

  // Return nothing until guid is validated.
  if (!hasValidated && isEmpty(rep)) {
    return <Loader active />
  }

  // Return children if guid has been validated and returned rep information.
  if (hasValidated && hasDispatched) {
    if (isNull(rep.RepDID)) {
      return <p>RepDID was not found.</p>
    } else if (!whitelistedCountries.includes(rep.country)) {
      return <p>This page is not available for {rep.country} reps.</p>
    } else {
      return children
    }
  }

  // Return session expired if guid has been validated but errored out.
  if (hasValidated && !hasDispatched) {
    return <p>Your session has expired.</p>
  }

  return null
}
