import { get } from './request'

export const languageApi = {
  getAllByCountry: async ({ country }) => {
    try {
      const response = await get(`/api/getLanguagesByCountry/:${country}`)
      return response
    } catch {
      console.error(`Problem retrieving languages by country: ${country}`)
    }
  },
  getLanguageById: async ({ DefaultLocaleID }) => {
    try {
      const response = await get(
        `/api/getLanguageFromLocale/:${DefaultLocaleID}`
      )

      if (typeof response === 'object' && response.recordset.length !== 0) {
        return response?.recordset[0]?.languageName
      } else {
        return ''
      }
    } catch {
      console.error(
        `Problem retrieving language by DefaultLocaleID: ${DefaultLocaleID}`
      )
    }
  },
}
