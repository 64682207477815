import Modal from 'components/modals/Modal'
import { 
  Box, 
} from '@newulife/common'
import { parseRichText } from 'helpers/contentful'

const TermsModal = ({ onClose, modalText, ...rest }) => {

  return (
    <Modal 
      id="terms-page-modal" 
      onClose={onClose} 
      modalStyles={{ overflow: 'auto', minHeight: '50%' }}
    >
      <Box 
        sx={{
          '& p': {
            paddingBottom: '20px',
          },
          '& h4': {
            fontWeight: 600,
            fontSize: '21px',
            lineHeight: '25px',
            marginBottom: '20px',
          },
          '& i': {
            fontStyle: 'italic',
          },
          '& ul li:last-of-type p': {
            paddingBottom: 0,
          },
          '& hr': {
            border: 'none',
            margin: '20px 0',
            height: '1px',
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        {modalText && parseRichText(modalText)}
      </Box>
    </Modal>
  )
}

export default TermsModal