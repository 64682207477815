import { createContext, useContext, useReducer } from 'react'
import { initialState } from 'constants/error'

const ErrorContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'showMessage':
      return { ...state, enabled: true, message: action.message }
    case 'hideMessage':
      return { ...state, enabled: false, message: '' }
    default:
      throw new Error(`Unhandled action type in ErrorContext: ${action.type}`)
  }
}

// Used to display error messages related to server/network problems to the user.
const ErrorContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ErrorContext.Provider value={{ state, dispatch }}>
      {children}
    </ErrorContext.Provider>
  )
}

// Custom hook to consume context.
const useError = () => {
  const context = useContext(ErrorContext)

  if (context === undefined) {
    throw new Error('useError must be used within a ErrorContextProvider')
  }

  return context
}

export { ErrorContextProvider, useError }
