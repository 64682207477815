import axios from 'axios'
import config from 'config'

// GET
export const get = async (endpoint, params = {}) => {
  try {
    const response = await axios.get(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// PUT
export const put = async (endpoint, params = {}) => {
  try {
    const response = await axios.put(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    )
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

// POST
export const post = async (endpoint, params = {}) => {
  try {
    const response = await axios.post(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// DELETE
export const remove = async (endpoint, params = {}) => {
  try {
    const response = await axios.post(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
