import React from 'react';

class ErrorBoundary extends React.Component {
  state = { error: false };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch() {
    this.props.callback();
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
