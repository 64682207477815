import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export const parseRichText = (value) => {
  // Matching general ui/ux for bold and regular text elements.
  return documentToReactComponents(value, {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="bold">{text}</span>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <>{children}</>;
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul style={{ listStyle: "disc" }}>
            {children.map((listItem, i) => {
              return listItem;
            })}
          </ul>
        );
      },
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  });
};

const countryMapping = {
  'USA': "United States",
  'CANADA': "Canada NFR",
  'AUSTRALIA': "Australia NFR",
  "NEW ZEALAND": "New Zealand NFR",
  "HONG KONG": "Hong Kong",
  'TAIWAN': "Taiwan",
};

// Match ByDesign country names to their respective whitelist name in Contentful.
export const parseWhitelistByCountry = ({ country }) => countryMapping[country] || ""
