import MobileMenuSVG from 'assets/svg/mobile-menu.svg'
import MobileMenuCloseSVG from 'assets/svg/mobile-menu-close.svg'
import UserAvatar from 'components/avatars/UserAvatar'
import LanguageDropdown from 'components/dropdowns/LanguageDropdown'
import { useApp } from 'contexts/AppContext'
import { parseFullName } from 'helpers/rep'
import { AppBar, Box, Stack, Typography } from '@newulife/common'

interface SiteNavBarProps {
  data: {
    fields: {
      text1: string;
      image: {
        fields: {
          file: {
            url: string;
          };
          title: string;
        };
      };
    };
  };
  mobileNavOpen?: boolean;
  setMobileNavOpen?: (open: boolean) => void;
  bp?: string;
  hideNav?: boolean;
}

export default function SiteNavBar({
  data,
  mobileNavOpen,
  setMobileNavOpen,
  bp,
  hideNav = false,
}: SiteNavBarProps) {
  const {
    state: {
      rep: { firstName, lastName, PublicImageUrl },
    },
  } = useApp()

  const {
    text1: loggedInAsText,
    image: {
      fields: {
        file: { url: imgSrc },
        title: imgAlt,
      },
    },
  } = data?.fields

  const fullName = parseFullName(firstName, lastName)

  return (
    <AppBar
      sx={{
        bgcolor: 'background.paper',
        color: 'text.main',
      }}
      position="fixed"
    >
      <Stack
        display="grid"
        gridTemplateColumns="auto 1fr"
        justifyContent={bp && {
          [bp]: 'flex-end',
        }}
        alignItems="center"
        alignContent="center"
        px={{
          xs: 2,
          sm: 3,
        }}
        py={1.5}
      >
        <Box
          maxWidth="100px"
          height="40px"
        >
          <img style={{ height: '100%' }} src={imgSrc} alt={imgAlt} />
        </Box>
        <Stack
          display="inline-grid"
          gridAutoFlow="column"
          gap={3}
          alignItems="center"
          alignContent="center"
          justifySelf="flex-end"
        >
          <Box>
            <LanguageDropdown />
          </Box>
          {!!fullName?.length && (
            <Box
              display={{
                xs: 'none',
                md: 'initial',
              }}
            >
              {PublicImageUrl && <UserAvatar url={PublicImageUrl} />}
              <Typography
                variant="body2"
                color="neutralCool.dark"
                style={{ display: 'grid', gap: '5px' }}
              >
                <span
                  data-testid={`header-${loggedInAsText.replace(/\W/g, '').toLowerCase()}`}
                >
                  {loggedInAsText}
                </span>
                {' '}
                <span
                  data-testid={`header-username`}
                >
                  <strong>
                    {fullName}
                  </strong>
                </span>
              </Typography>
            </Box>
          )}
          {!hideNav && setMobileNavOpen ? (
            <Box
              display={bp && {
                [bp]: 'none',
              }}
              height="40px"
              width="40px"
              sx={{
                cursor: 'pointer',
              }}
              onClick={(e) => setMobileNavOpen(!mobileNavOpen)}
            >
              <img
                src={mobileNavOpen ? MobileMenuCloseSVG : MobileMenuSVG}
                alt="mobile-menu"
                style={{ width: '100%' }}
              />
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </AppBar>
  )
}
