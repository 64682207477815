export const mockData = {
  "post-autoresponder-details": {
    response:{
      status: 500,
      data: {
        id: generateRandomHexString(32),
        code: "BYDESIGN_BAD_REQUEST",
        message: "Unable to update rep info",
      },
    },
  },
  // ... other types as needed
};

export const mockApiCall = (type) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Simulate different behavior based on the type
      if (mockData[type]) {
        reject(mockData[type]);
      } else {
        const error = {
          response: {
            status: 500,
            data:{
              message: "Internal Server Error",
            },
          },
        };
        reject(error);
      }
    }, 1000);
  });
};

// Function to generate random hex string
function generateRandomHexString(length) {
  let result = "";
  const characters = "0123456789abcdef";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
