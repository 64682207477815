import { defaultLanguage } from 'constants/language'
import { defaultRep } from 'constants/rep'

export const initialState = {
  isLoading: true,
  locale: defaultLanguage.locale,
  rep: defaultRep,
  languages: [
    {
        "lang": "한국어",
        "locale": "ko-KR",
        "DefaultLocaleID": 73
    },
    {
        "lang": "English",
        "locale": "en-US",
        "DefaultLocaleID": -24
    },
    {
      "lang": "English",
      "locale": "en-US",
      "DefaultLocaleID": 1
    },
  ],
  accountTypes: [],
}
