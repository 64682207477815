import { useEffect, useState, useContext, createContext, useMemo } from 'react'
import { getEntryByLocale } from 'api/contentful'
import { useApp } from 'contexts/AppContext'
import { get } from 'lodash'

export const useContentful = (entryId) => {
  // Context
  const {
    state: { locale },
  } = useApp()

  // State
  const [data, setData] = useState()

  useEffect(() => {
    if (entryId) {
      getEntryByLocale(entryId, locale).then((res) => {
        const { error } = res
        !error && setData(res)
      })
    }
  }, [entryId, locale])

  return data
}

export const ContentfulContext = createContext()

export const ContentfulProvider = ({
  id,
  defaultData,
  children,
}) => {
  const contentfulData = useContentful(id)
  const data = defaultData || contentfulData

  if (!data) return null

  return (
    <ContentfulContext.Provider
      value={data}
    >
      {children}
    </ContentfulContext.Provider>
  )
}

export const useContentfulContext = ({
  entryPoint,
}) => {
  const context = useContext(ContentfulContext)
  return useMemo(() => {
    if (!entryPoint) return context || {}
    return get(context, entryPoint, {})
  }, [context, entryPoint])
}