import { AddressText } from 'components/texts/AddressText'
import { useModal } from 'contexts/ModalContext'
import { parseRichText } from 'helpers/contentful'
import { useContentful } from 'hooks/useContentful'
import Modal from './Modal'
import { Button, Typography, Stack } from '@newulife/common'

export default function InvalidAddressModal({ addressType, form, callback }) {
  const { dispatch } = useModal()
  const data = useContentful('B3isxCN7NJCwHpTW3fST4') // Invalid Address Modal Info

  if (!data) return null

  const onCancel = () => {
    dispatch({ type: 'close' })
  }

  const onSubmit = () => {
    callback()
    dispatch({ type: 'close' })
  }

  const {
    title,
    item1: shippingText,
    item2: billingText,
    item3: cancelText,
    item4: submitText,
    richText,
  } = data.fields

  return (
    <Modal
      id="invalid-address-modal"
    >
      <Stack
        gap={4}
      >
        <Stack
          gap={2}
        >
          <Typography
            variant="header1"
            color="neutralCool.dark"
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography
            variant="body3"
            maxWidth="440px"
            textAlign="center"
            sx={{
              mx: 'auto',
            }}
          >
            {parseRichText(richText)}
          </Typography>
        </Stack>
        <Stack
          display="grid"
          justifyItems="center"
          gap={2}
          textAlign="center"
        >
          {addressType === 'Shipping' && (
            <Typography
              variant="header2"
              color="neutralCool.main"
            >
              {shippingText}
            </Typography>
          )}
          {addressType === 'Billing' && (
            <Typography
              variant="header2"
              color="neutralCool.main"
            >
              {billingText}
            </Typography>
          )}
          <AddressText addressType={addressType} form={form} />
        </Stack>
        <Stack
          display="grid"
          gridAutoFlow={{
            sm: 'column',
          }}
          gap={2}
          justifyContent="center"
        >
          <Button
            data-testid={`${submitText?.replace(/\W/g, '').toLowerCase()}_btn`}
            onClick={onSubmit}
          >
            {submitText}
          </Button>
          <Button
            data-testid={`${cancelText?.replace(/\W/g, '').toLowerCase()}_btn`}
            variant="outlined"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}
